<template>
  <section class="page-example">
    <h1>Example</h1>
    <h2 @click="testStore">Store Test: {{ test }}, Click This</h2>
    <h2 @click="testLanguage">Language Test: {{ $t('example.test') }}, Click This</h2>
    <h3 @click="requestLogin">
      測試接口 {{ networkTest }}
    </h3>
    <el-input />

    <v-date-picker v-model="textDate" />

    <div>
      <v-select
        v-model="textSelect"
        :list="list" />
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { login } from '@/api'

export default {
  name: 'ExamplePage',
  data() {
    return {
      networkTest: '',
      textDate: '',
      textSelect: '',
      list: [
        {
          id: 1,
          name: 'Test'
        },
        {
          id: 2,
          name: 'Test Next'
        }
      ]    
    }
  },
  computed: {
    ...mapState('example', ['test'])
  },
  methods: {
    ...mapActions('example', ['setExample']),
    testStore() {
      this.setExample(this.test+1)
      // this.$MessageBox({
      //   message: '哈哈哈'
      // })
    },
    testLanguage() {
      // const lang = localStorage.getItem('lang') === 'en-US' ? 'zh-HK' : 'en-US'
      // localStorage.setItem('lang', lang)
      // this.$i18n.locale = lang
      // this.$vantLocale(lang)
      // Vue.prototype.$lang = lang
      this.changeLanguage()
      this.$ConfirmBox({
        title: '改了哦',
        message: '好了知道了'
      }).then(() => {
        // confirm
      })
        .catch(() => {
        // cancel
        })
    },
    requestLogin() {
      this.networkTest = '-> 別等了，看控制臺'
      this.network().login()
    },
    network() {
      return {
        login: async () => {
          let { data } = await login({
            username: 'test',
            password: 'test'
          })
          console.log(data)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page-example {
  padding: 50px;
    text-align: center;
    h1, h2, h3, div {
        margin-top: 20px;
    }
}
</style>